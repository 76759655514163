<!--
 * @Author: your name
 * @Date: 2021-01-21 11:34:45
 * @LastEditTime: 2021-05-19 18:47:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\searchmsg\index.vue
-->
<template>
  <div>
    <navigation :msg="msg"></navigation>
    <div
      v-for="(item, index) in selectbacklist"
      v-bind:key="index"
      class="beiclassthe"
    >
      <div class="bei1">
        <div class="bei2">
          <div>{{ item.name }}</div>
          <div>{{ item.mobile }}</div>
        </div>
        <div v-show="item.survey == 1" class="jiegcl back4">未完成背调</div>
        <div v-show="item.survey == 2" class="jiegcl back3">背调进行中</div>
        <div v-show="item.survey == 3" class="jiegcl back2">授权被拒绝</div>
        <div v-show="item.survey == 4" class="jiegcl back1">已完成背调</div>
        <div v-show="item.survey == 5" class="jiegcl back2">超时未授权</div>
        <div v-show="item.survey == 7" class="jiegcl back3">待支付</div>
      </div>
      <div class="bei3">
        <img src="../../assets/defend.png" />
        <div>报告编号：{{ item.select_no }}</div>
      </div>
      <div class="seeclass">
        <div class="timeclass">
          <img src="../../assets/time.png" alt="" />
          <div>{{ item.select_time }}</div>
        </div>
        <div>
          <div v-show="item.survey == 4" @click="seeback(item)" class="seebao">
            查看报告
          </div>
        </div>
      </div>
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsgclass"></div>
  </div>
</template>
<script>
import loading from "../../components/loading/index";
export default {
  components: {
    loading,
  },
  data() {
    return {
      msg: "",
      requestmsg: "",
      selectbacklist: "",
      pageno: 0,
      datamsg: "",
    };
  },
  created() {
    this.requestmsg = this.$route.query;
  },
  methods: {
    /**
     * 查看报告详情
     */
    seeback(msg) {
      console.log(msg);
      localStorage.setItem("z_id", msg.z_id);
      localStorage.setItem("r_id", msg.r_id);
      this.$router.push("/reportview2");
    },
    /**
     * 查询背调信息（条件）
     */
    loaddom() {
      if (this.requestmsg.ismeth) {
        this.datamsg = {
          reqType: "profile",
          start_time: "",
          end_time: "",
          page: ++this.pageno,
          // page: 1,
          article: 15,
          name: this.requestmsg.selectmsg,
          product_edition: "",
          survey: "",
        };
      } else {
        this.msg = this.requestmsg.selectmsg;
        this.datamsg = {
          reqType: "profile",
          accredit:
            this.msg == "短信授权" ? "1" : this.msg == "二维码授权" ? "3" : "",
          start_time: "",
          end_time: "",
          page: ++this.pageno,
          // page: 1,
          article: 15,
          name: "",
          product_edition: "",
          survey:
            this.msg == "待支付"
              ? "7"
              : this.msg == "未完成背调"
              ? "1"
              :this.msg == "背调进行中"
              ?'2'
              : this.msg == "授权被拒绝"
              ? "3"
              : this.msg == "已完成背调"
              ? "4"
              : this.msg == "超时未授权"
              ? "5"
              : "",
        };
      }
      this.$refs.loades
        .loadhttpes("/firm/v1/Background/reportList", this.datamsg)
        .then((res) => {
          this.selectbacklist = res.arraymsg;
        });
    },
  },
};
</script>
<style scoped>
.back4 {
  color: #979797;
}
.back3 {
  color: #16ca4d;
}
.back2 {
  color: #fa4d08;
}
.back1 {
  color: #088ffa;
}
.nomsgclass {
  height: 0.2rem;
}
.seebao {
  background-color: #fde1d4;
  color: #f76729;
  font-size: 0.24rem;
  line-height: 0.16rem;
  padding: 0.17rem 0.24rem;
  border-radius: 0.1rem;
}
.seeclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeclass img {
  width: 0.19rem;
  height: 0.19rem;
  margin-right: 0.2rem;
}
.timeclass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  line-height: 0.32rem;
  color: #616b80;
  margin-top: 0.1rem;
  font-weight: 400;
}
.bei3 img {
  width: 0.28rem;
  height: 0.3rem;
  margin-right: 0.14rem;
}
.bei3 {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  line-height: 0.3rem;
  color: #141f33;
  font-weight: 500;
  margin-top: 0.2rem;
}
.jiegcl {
  font-size: 0.24rem;
  line-height: 0.3rem;
}
.bei2 {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.34rem;
  font-weight: 500;
  color: #141f33;
}
.bei2 > div:first-child {
  margin-right: 0.15rem;
}
.bei1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.beiclassthe {
  background-color: #ffffff;
  border-radius: 0.1rem;
  margin: 0.14rem 0.3rem;
  padding: 0.38rem 0.3rem;
}
.topsearch1 img {
  width: 0.35rem;
  height: 0.35rem;
}
.topsearch1 input {
  width: 100%;
}
.topsearch1 {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  margin: 0.31rem 0.3rem;
  padding: 0.2rem 0.34rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.4rem;
  border-radius: 0.1rem;
}
input {
  border: none;
}
input::placeholder {
  color: #616b80;
}
</style>